.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page .banner {
  width: 100%;
  height: auto;
  cursor: pointer;
  animation: zoomIn 1s;
}

.home-page .padding-top {
  padding-top: 30px;
}

.home-page .top-products {
  margin-top: 20px;
}

.button-container {
  display: flex;
  margin-top: 10px;
  padding: 0 5px;
  gap: 5px;
}

.button-container button {
  padding: 6px 9px;
  font-size: 13px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-container button:hover {
  background-color: #ccc;
  color: #fff;
}

.carousel .image-holder {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 auto;
  padding: 10px;
  width: 70%; 
  max-width: 320px;
  margin-bottom: 10%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel .image-holder img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
  object-fit: contain;
}

.rounded {
  border-radius: 4px; 
}

/* .control-dots{
  top:51%;
  max-height: 10px;
} */

.carousel ul.slide {
  max-height: 400px;
}

.carousel .slide li {
  height: 100%;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}