.btn-like {
    cursor: pointer;
}

.bi-heart, .bi-heart-fill  {
    /* position: absolute;
    bottom: -15px;
    right: -10px; */
    /* transform: translate(-50%, -50%); */
    z-index: 1;
    font-size: 30px;
}

.bi-heart {
    color: #000;
}

.bi-heart-fill {
    color:var(--main-color);
}