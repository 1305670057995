.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 35%;
}

.spinner {
  border: 16px solid #e0dfdf; /* Light gray */
  border-top: 16px solid #EF492A;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  animation-fill-mode: forwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
  