@font-face {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../static/fonts/Radomir Tinkov - Gilroy-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../static/fonts/Radomir Tinkov - Gilroy-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../static/fonts/Radomir Tinkov - Gilroy-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-RegularItalic-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../static/fonts/Radomir Tinkov - Gilroy-RegularItalic.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../static/fonts/Radomir Tinkov - Gilroy-SemiBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "Gilroy-RegularItalic-☞";
  font-style: italic;
  font-weight: 400;
  src: url("../static/fonts/Luxstay-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Font Awesome 5 Pro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../static/fonts/Font Awesome 5 Pro-Regular-400.otf") format("opentype");
}

/* V`riables */

:root {
  --main-color: #ef492c;
  --black-2: rgba(0, 0, 0, 0.051);
  --black-3: rgba(0, 0, 0, 0.102);
  --black: rgba(0, 0, 0, 1);
  --shark: rgba(35, 38, 47, 1);
  --english-walnut: rgba(62, 42, 42, 1);
  --wedgewood: rgba(87, 126, 157, 1);
  --regent-gray: rgba(135, 135, 156, 1);
  --oslo-gray: rgba(135, 137, 142, 1);
  --regent-gray-2: rgba(135, 146, 157, 1);
  --star-dust: rgba(161, 161, 161, 1);
  --foggy-gray: rgba(200, 209, 182, 1);
  --pale-leaf: rgba(201, 209, 185, 1);
  --bon-jour: rgba(223, 223, 230, 1);
  --seashell: rgba(241, 241, 241, 1);
  --concrete: rgba(242, 242, 242, 1);
  --cultured-pearl: rgba(245, 245, 245, 1);
  --desert-storm: rgba(248, 249, 249, 1);
  --romance: rgba(252, 252, 253, 1);
  --white: rgba(255, 255, 255, 1);

  --font-size-xxxxs: 10px;
  --font-size-xxxs: 11px;
  --font-size-xxs: 12px;
  --font-size-xs: 14px;
  --font-size-s: 16px;
  --font-size-m: 20px;

  --font-family-font_awesome_5_pro-regular: "Font Awesome 5 Pro-Regular";
  --font-family-gilroy-medium: "Gilroy-Medium";
  --font-family-gilroy-regular: "Gilroy-Regular";
  --font-family-gilroy-regularitalic-regular: "Gilroy-RegularItalic-Regular";
  --font-family-gilroy-regularitalic-☞: "Gilroy-RegularItalic-☞";
  --font-family-gilroy-semibold: "Gilroy-SemiBold";
  --font-family-gilroy-semibolditalic-regular: "Gilroy-SemiBoldItalic-Regular";
  --font-family-prompt: "Prompt";
}

/* Classes */

.gilroy-regularitalic-regular-normal-black-11px {
  color: var(--black);
  font-family: var(--font-family-gilroy-regularitalic-regular);
  font-size: var(--font-size-xxxs);
  font-weight: 400;
  font-style: normal;
}
.gilroy-medium-white-12px {
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xxs);
  font-weight: 500;
  font-style: normal;
}
.gilroy-medium-black-12px {
  color: var(--black);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xxs);
  font-weight: 500;
  font-style: normal;
}
.border-1px-black {
  border-width: 1px;
  border-style: solid;
  border-color: var(--black);
}
.prompt-normal-regent-gray-12px {
  color: var(--regent-gray-2);
  font-family: var(--font-family-prompt);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  font-style: normal;
}
.gilroy-medium-romance-16px {
  color: var(--romance);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
}
.gilroy-medium-oslo-gray-14px {
  color: var(--oslo-gray);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
}
.gilroy-semi-bold-shark-20px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-semibold);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
}
.gilroy-medium-shark-16px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
}
.border-1px-seashell {
  border-width: 1px;
  border-style: solid;
  border-color: var(--seashell);
}
.gilroy-semi-bold-shark-16px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}
.gilroy-regular-normal-white-10px {
  color: var(--white);
  font-family: var(--font-family-gilroy-regular);
  font-size: var(--font-size-xxxxs);
  font-weight: 400;
  font-style: normal;
}
.gilroy-regular-normal-shark-14px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-regular);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
}
.gilroy-medium-shark-12px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xxs);
  font-weight: 500;
  font-style: normal;
}
.gilroy-medium-black-11px {
  color: var(--black);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xxxs);
  font-weight: 500;
  font-style: normal;
}
.gilroy-medium-oslo-gray-16px {
  color: var(--oslo-gray);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
}
.border-1px-bon-jour {
  border-width: 1px;
  border-style: solid;
  border-color: var(--bon-jour);
}
.border-3px-black {
  border-width: 3px;
  border-style: solid;
  border-color: var(--black);
}
.border-6px-black {
  border-width: 6px;
  border-style: solid;
  border-color: var(--black);
}
.gilroy-medium-star-dust-12px {
  color: var(--star-dust);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xxs);
  font-weight: 500;
  font-style: normal;
}
.gilroy-regular-normal-shark-16px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.gilroy-semibolditalic-regular-normal-black-11px {
  color: var(--black);
  font-family: var(--font-family-gilroy-semibolditalic-regular);
  font-size: var(--font-size-xxxs);
  font-weight: 400;
  font-style: normal;
}
.gilroy-medium-shark-14px {
  color: var(--shark);
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
}
.gilroy-regular-normal-regent-gray-12px {
  color: var(--regent-gray);
  font-family: var(--font-family-gilroy-regular);
  font-size: var(--font-size-xxs);
  font-weight: 400;
  font-style: normal;
}
.gilroy-semi-bold-english-walnut-16px {
  color: var(--english-walnut);
  font-family: var(--font-family-gilroy-semibold);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
}


/* End Style Guide */

* {
  font-family: 'Gilroy-Regular';
  box-sizing: border-box;
}

body {
  /* background-color: rgba(0, 0, 0, 0.3); */
  margin: 0;
  padding: 0;
   /*height: 100vh; /* make body take full height of viewport */
  height: calc(var(--vh, 1vh) * 100); /* Add this */
  display: flex; 
  justify-content: center;
  overflow: hidden; /* Prevent body from scrolling */
}

#root {
  width: 100%; /* Ensure root takes up full width */
  /* height: 100%; /* Ensure root takes up full height */
  height: calc(var(--vh, 1vh) * 100); /* Add this */
  display: flex;
  justify-content: center;
  overflow: hidden; /* Prevent root from scrolling */
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

a {
  color: #000;
  text-decoration: none;
}

.container-app {
  width: 414px;
  height: calc(var(--vh, 1vh) * 100); /* Add this */
  background-color: #fff;
  text-align: center;
  z-index: 10;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  overflow: hidden; /* Prevent container-app from scrolling */
}

.header {
  position: sticky;
  top: 0;
  z-index: 11; /* Ensure header is above other content */
}

.main-content {
  overflow-y: auto; /* Add scroll to main content if it exceeds the container size */
  flex-grow: 1; /* Make main-content take up all remaining space in the container */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.main-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.navbar {
  position: sticky;
  bottom: 0;
  z-index: 11; /* Ensure navbar is above other content */
}

.screen {
  padding: 20px 10px 20px;
  min-height: 100vh;
}

.btn-close {
  float: right;
  margin: 1px 1px 0 0;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #DFDFE6;
  font-family: var(--font-family-gilroy-medium);
  font-size: var(--font-size-s);
}

.btn-close:hover {
  background-color: #F5F5F5;
}

.close-app {
  min-width: 100%; 
  min-height: 860px;
  position: absolute;
}

.btn { 
  font-family: 'Gilroy-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.color-stailor {
  color: var(--main-color);
}

.btn-medium {
  -webkit-appearance: none; /* Disables default button styles in iOS */
  width: 140px;
  height: 43px;
  color: #fff;
  border: none;
  background: #000;
  border: 1px solid #000;
  border-radius: 4px;
  cursor: pointer;
}

.btn-big {
  -webkit-appearance: none; /* Disables default button styles in iOS */
  /* width: 354px; */
  width: 100%;
  height: 56px;
  background: #fff;
  color: #000;
  letter-spacing:2px;
  border: 1px solid #000;
  border-radius: 4px;
  cursor: pointer;
}

.btn-hover:hover{
  background-color: #EF492A ;
}

.bgcolor-stailor {
  background-color: var(--main-color);
}

.btn-bgcolor-stailor {
  background-color: var(--main-color);
  color: #fcfcfd;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
}

.mrg-0 {
  margin: 0;
}