.header {
    padding: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    text-align: center;
    border-bottom: 1px solid #BEBDBD;
}

.header-logo {
    width: 40%;
    margin: 0 auto;
}