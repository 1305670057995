.screen-profile .container-personal-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    }

.username-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.username-container > i {
    cursor: pointer;
    font-size: 20px;
}

.txt-description {
    left: 71px;
    top: 307px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    color: #000000;
}

.img-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: center;
    margin-bottom: 5%;
}

.btn-edit-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 500;
    color: #000;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 20px;
}

.screen-profile .container-buttons {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.screen-profile .container-buttons .btn {
    border: none;
    border-radius: 0;
    border-left: 1px solid #000;
}

.screen-profile .container-buttons .btn:first-child {
    border-left: none;
}

.screen-profile .services-section {
    margin-bottom: 10px;
}