/* .authentication-page {
    min-height: 640px;
    padding: 30px 30px 20px;
} */


#loginDiv iframe {
    max-width: 100%;
}

.image-sign {
    width: 220px;
    height: 220px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.txt-question {
    height: 10px;
    text-align: center;
    font-family: 'Gilroy-RegularItalic';
    line-height: 15px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.btn-signup-signin {
    border-radius: 4px;
    border: none;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FCFCFD;
}