.chatbot-container {
  padding: 10px;
  /* min-height: inherit; */
}

.chatbot-container {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.chatbot-conversation {
  min-height: 70vh;
  height: calc(70vh - 150px); /* the height of the container - the height of the carousel at the bottom */
  overflow-y: scroll;
  padding: 10px 0 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  background-color: #fff;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);

  /* Experimental */
  position: relative;
  overflow-x: hidden; /* This will prevent the parent from scrolling horizontally */
}

.chatbot-message {
  /* display: inline-block; */
  margin-bottom: 10px;
  padding: 0 15px;
  border-radius: 4px;
  /* text-align: center; */
  min-width: 30%;
  max-width: 70%;
}

.chatbot-container .user, .chatbot-container .bot {
  display: flex;
  flex-direction: column;
}

.chatbot-container .user {
  background-color: #f2f2f2;
  border: 1px solid var(--main-color);
  text-align: right;
  float: right;
  align-items: flex-end;
  min-width: 35%;
}

.chatbot-container .bot {
  background-color: #fff;
  border: 1px solid var(--main-color);
  text-align: left;
  float: left;
  align-items: flex-start;
  min-width: 70%;
}

.user p {
  text-align: right;
}

.bot p {
  text-align: left;
}

.chatbot-input-form {
  display: flex;
  justify-content: space-between;
  background-color: transparent;
}

.chatbot-input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-right: 1%;
}

.chatbot-username {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
}

.user .chatbot-username {
  text-align: right;
}

.bot .chatbot-username {
  text-align: left;
}

.chatbot-container .user .username {
  margin-right: 10px;
  font-weight: bold;
}

.chatbot-container .bot .username {
  margin-left: 10px;
  font-weight: bold;
}

/* Prevent zooming on iOS devices and Safari */
.chatbot-input {
  font-size: 14px;
  /* Other styles for your textarea */
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.chatbot-send-button {
  background-color: #000000;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 10px;
  height: 40px;
  width: 16%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.chatbot-send-button:hover {
  background-color: var(--main-color);
  transform: scale(1.1);
}

/* width and height of the scrollbar track */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* width and height of the scrollbar thumb (slider) */
::-webkit-scrollbar-thumb {
  background-color: black;
  border-radius: 4px;
}

/* hover style for the scrollbar thumb (slider) */
::-webkit-scrollbar-thumb:hover {
   background-color: #000;
}

/* Style the scrollbar track */
.chatbot-conversation {
  scrollbar-width: thin;
  scrollbar-color: #000 #000
}

/* Style the scrollbar thumb (slider) */
.chatbot-conversation::-webkit-scrollbar-thumb {
  background-color: #000;
}

/* Hover style for the scrollbar thumb (slider) */
.chatbot-conversation::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

.chatbot-carousel {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 10px;
  height: 150px;
}

.chatbot-carousel-item {
  cursor: pointer;
}

.chatbot-carousel-item img {
  max-width: 35%;
}

.loading-dots {
  width: 1rem;
  height: 1rem;
  font-size: 3rem;
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 5px;
  animation: loading-dots 1s steps(3, end) infinite;
}

@keyframes loading-dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
  }
}