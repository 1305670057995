.container-garment-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container-garment-preview .container-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    max-width: 90%;
}

.container-garment-preview .container-product img {
    display: block;
    max-width: 100%;
}

.container-garment-preview .txt-style {
    margin-top: 30px;
}

.container-garment-preview .container-btns {
    display: flex;
    justify-content: space-evenly;
    gap: 50px;
    padding-top: 20px;
}

.container-product-actions {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-top: 1px solid #BEBDBD;
    width: 100%;
}

.container-product-actions i {
    cursor: pointer;
    font-size: 26px;
}