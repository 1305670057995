.user-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
}

.profile-section {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-heading {
  color: #333;
  margin-top: 1rem;
}

.settings-section {
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.settings-section.animate {
  opacity: 1;
  transform: translateY(0);
}

.section-heading {
  color: #333;
  margin-bottom: 1.5rem;
}

.settings-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
}

.settings-item i {
  font-size: 1.2rem;
  color: #333;
  margin-right: 1rem;
}

.settings-item p {
  margin: 0;
  color: #333;
}

.settings-item:hover {
  background-color: #f1f1f1;
}
