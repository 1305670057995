.burger-menu {
  visibility: hidden;
  position: absolute;
  top: 66px;
  right: 0;
  width: 207px;
  background: rgba(246, 246, 246, 0.90); /* Adjust the alpha value (0.9) to control the transparency */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a box shadow for a subtle effect */
  transform: translateX(100%);
  transition: transform 0.5s ease-out; 
  z-index: 50; 
  padding: 0 10px;
  border-left: 1px solid #BEBDBD;
  overflow-y: auto;
}

.burger-menu.burger-menu-open {
  visibility: visible;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-burger-menu i {
  font-size: 35px; /* Increase the font-size to make the burger icon bigger */
  cursor: pointer;
}

.burger-menu-open button {
  width: 100%;
}

.btn-menu-options {
  -webkit-appearance: none; /* Disables default button styles in iOS */
  padding: 10px 0;
  background-color: #bebdbd00;
  color: #000;
  border: none;
  /* border-top: 1px solid #000; */
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: space-between;  /* aligns children along a line with space between them */
  align-items: center;  /* aligns children in the cross-axis */
}

.btn-menu-options .button-text {
  margin-left: 10px;  /* centers the text */
}

.btn-menu-options .button-arrow {
  margin-right: 10px;  /* adjust as needed */
}
.conditions-txt{
  margin-bottom: 10px;
  margin-top: 100px;
}


/* Scrollbar test design */

.burger-menu::-webkit-scrollbar {
  width: 3px; /* Adjust the width to make the scrollbar narrower */
}

.burger-menu::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4); /* Customize the scrollbar thumb color */
}

.burger-menu::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Customize the hover effect */
}

.burger-menu::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Customize the scrollbar track color */
}

.burger-menu::-webkit-scrollbar-track-piece:start {
  background-color: transparent; /* Add padding at the start */
}

.burger-menu::-webkit-scrollbar-track-piece:end {
  background-color: transparent; /* Add padding at the end */
}
