.container-gender {
  padding-top: 20px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_style:hover{
  background-color: #EF492A;
}

.screen-gender .txt-gender {
  /* font-family: 'Gilroy'; */
  font-style: normal;
  line-height: 20px;
  text-align: center;
  color: #23262F;
}

.txt-secondary {
  /* font-family: 'Gilroy'; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  align-items: flex-end;
  text-align: center;
  color: #23262F;
}

.hr-with-text {
  border-top: 1px solid black;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 30px 0;
}

.hr-with-text span {
  position: absolute;
  top: -12px;
  padding: 0 20px;
  background-color: #fff;
}

.container-gender .disabled {
  color: rgba(16, 16, 16, 0.3);
  border-color: rgba(16, 16, 16, 0.3);
  cursor: auto;
}

.container-gender > a {
  width: 100%;
}