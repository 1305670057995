.screen-add-style form.form-add-style, .screen-add-style div.container-products, .screen-add-style div.container-products > div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.form-add-style textarea {
    min-width: 100%;
    max-width: 100%;
}

.screen-add-style div.container-products > div {
    gap: 15px;
}

.form-add-style .form-group {
    width: 100%;
}

.form-add-style .form-group label {
    /* color: #87898E; */
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 500;
}

.form-add-style input, .form-add-style select, .form-add-style textarea {
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0 16px;
    width: 100%;
    height: 46px;
    font-family: 'Gilroy';
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #DFDFE6;
    border-radius: 4px;
}

.form-add-style input[type=file] {
    padding: 10px 16px;
}