.screen-recommendations {
    padding: 30px 30px 20px;
}

.screen-recommendations .container-btns {
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.screen-recommendations .container-thumbnails {
    display: flex;
    justify-content: space-around;
    padding: 40px 0;
    cursor: pointer;
}
