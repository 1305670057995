.navbar {
  display: flex;
  justify-content: space-between;
  background: #F6F6F6;
  padding: 2px;
  border-top: 1px solid #BEBDBD;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.navbar .nav-item {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbar img {
  max-width: 55%;
  border-radius: 50%;
  border: 1.5px solid black;
}

.navbar i {
  font-size: 32px;
  color: #000;
}

.nav-profile {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-size: cover;
  background-repeat: no-repeat;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 400px;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 35px;
  cursor: pointer;
}

.modal-content button {
  width: 100%;
  color: #fcfcfd;
  text-transform: uppercase;
  border: none;
  animation: button-scale 0.3s ease-in-out;
}

@keyframes button-scale {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}

.modal {
  animation: fade-in 0.3s ease-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
  
