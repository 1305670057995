.screen-feedstyles {
    padding-top: 10px;
}

.container-filters-styles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    gap: 10px;
}

.container-filters-styles button {
    min-height: 40px;
    /* width: 120px; */
    width: 30%;
    padding: 6px 9px;
    font-size: 13px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease;
}

.span-toggle-description {
    color: #5e5e5e;
    cursor: pointer;
}

.style-description {
    margin-bottom: 40px;
}

.screen-feedstyles > .container-style {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.screen-feedstyles > .container-style > .container-action-buttons {
    width: 90%;
}