.container-coupons {
  padding-top: 20px;
}

.coupon-image {
  cursor: pointer;
  max-width: 100%;
}

.screen-coupons button {
  padding: 20px 0px;
  width: 100%;
  height: 56px;
  background: var(--main-color);
  border-radius: 4px;
  border: none;
  letter-spacing: 2px;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  color: #FCFCFD;
  cursor: pointer;
  animation: button-scale 0.3s ease-in-out;
}

.coupon-code-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.coupon-code {
  background-color: #f0f0f0;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  flex-grow: 1;
  user-select: all;
}

.coupon-code button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}
