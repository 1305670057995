.screen-edit-profile .form-edit-profile {
  padding: 0 10%;
}

.screen-edit-profile .username-box input {
  background-color: transparent;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border: 1px solid #a9a9a9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.screen-edit-profile .txt-username {
  width: 38%;
  height: 17px;
  left: 36px;
  top: 298px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

.screen-edit-profile .txt-email {
  width: 30%;
  height: 17px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

.screen-edit-profile .txt-phone {
  width: 50%;
  height: 17px;
  left: 36px;
  top: 298px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

.screen-edit-profile .txt-password {
  width: 40%;
  height: 17px;
  left: 36px;
  top: 298px;
  font-family: 'Gilroy-SemiBold';
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

.screen-edit-profile .username-box:last-child {
  margin-bottom: 20px;
}

.screen-edit-profile button {
  margin-top: 15px;
}

.screen-edit-profile .txt-label {
  text-align: left;
  font-size: 18px;
  font-weight: 800;
}