.container-colors {
    padding-top: 20px;
}

.btn-color {
    margin: 0 20px 25px;
    width: 90px;
    height: 56px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.black {
    background: #000000;
}

.white{
    background: #FFFFFF;
    border: 3px solid #6e6c6c;
}

.gray{
    background: #8E8E93;
}

.beige{
    background: #C5C58F;
}

.red{
    background: #FF0000;
}

.pink{
    background: #FFC0CB;
}

.blue{
    background: #0000FF;
}

.green{
    background: #00FF00;
}

.purple{
    background: #800080;
}

.orange{
    background: #FFA500;
}

.yellow{
    background:#FFFF00;
}

.brown{
    background: #964B00;
}