.button_style {
  display: inline;
  margin: 10px 10px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 40%;
  height: 50px;
}

.button_style:hover {
  background-color: #EF492A;
} 