.container-img-thumbnail {
    position: relative;
    width: 100px;
    height: 105px;
    background: rgba(217, 217, 217, 0.7);
    border-radius: 4px;
}

.container-img-thumbnail > .not-approved {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    z-index: 5;
}

.container-img-thumbnail > img {
    width: inherit;
    border-radius: inherit;
    height: inherit;
}