.landing-page {
    padding-bottom: 10px;
    min-height: 640px;
}

.landing-page img {
    width: 100%;
}

.landing-page button {
    padding: 20px 0px;
    width: 95%;
    height: 56px;
    background: var(--main-color);
    border-radius: 4px;
    border: none;
    letter-spacing: 2px;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FCFCFD;

    
    cursor: pointer;
    margin-top: 25px;
}