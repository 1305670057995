.personal-info .text-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3%;
}

.personal-info .text-box .label-text {
  flex: 1;
  text-align: left;
}

.personal-info .text-box input, .personal-info .text-box textarea  {
  border: none;
  background-color: transparent;
  font-size: 16px;
  height: 40px;
  border-bottom: 1px solid #a9a9a9;
  border-radius: 0;
  color: #a9a9a9;
  flex: 2;
  text-align: left;
}

.text-box input:focus, .text-box textarea:focus {
  border-bottom: 2px solid black;
  outline: none;
  color: black;
}

/* .warning-box {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.5); /* Red color with transparency */ /*
  color: #fff;
  font-weight: bold;
} */

.personal-info .text-box .custom-select {
  border: none;
  background-color: transparent;
  font-size: 16px;
  height: 40px;
  border-bottom: 1px solid #a9a9a9;
  border-radius: 0;
  color: #a9a9a9;
  flex: 2;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.personal-info .text-box .custom-select:focus {
  border-bottom: 2px solid black;
  outline: none;
  color: black;
}

.personal-info .checkbox-color-container, .personal-info .checkbox-style-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
  align-items: baseline;
  justify-content: space-around;
}

.personal-info .checkbox-color-container label {
  display: inline-block;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.personal-info .checkbox-color-container label input[type="checkbox"] {
  display: none;
}

.personal-info .checkbox-color-container label span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.personal-info .checkbox-color-container label input[type="checkbox"]:checked + span {
  border: 2px solid black;
}

.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: var(--main-color); /* Change to your preferred color */
}

.checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
