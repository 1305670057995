.form-auth {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background-color: transparent;
}

#signUpDiv {
    margin-bottom: 15px;
}

#signUpDiv iframe {
    max-width: 100%;
}

.form-auth label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.form-auth input {
    border: none;
    background-color: transparent;
    font-size: 16px;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #a9a9a9;
    border-radius: 0;
    color: #a9a9a9;
    text-align: left;
}

.form-auth input:focus {
    border-bottom: 2px solid black;
    outline: none;
    color: black;
  }

.form-auth button {
    border: none;
    max-width: 100%;
}

/* Styling the information box */
.screen-signup .information {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.screen-signup .information img {
    border-radius: 50%;
}